import axiosAdmin from '../scripts/axios-admin'

// const MEDIAS_MOCK_DATA = new Array(25).fill(0).map(() => {
//   const isImage = Math.random() > 0.5
//   return {
//     id: Math.round(Math.random() * 1000),
//     name: 'Nouveau nom patch 2',
//     type: isImage ? 'image' : 'video',
//     url: null,
//     width: !isImage ? (Math.random() > 0.6 ? 3 : 1) : 1,
//     height: isImage ? (Math.random() > 0.5 ? 2 : 5) : 5,
//     createdAt: '2021-02-01T15:29:18.747Z',
//     updatedAt: '2021-02-01T16:57:59.186Z',
//     origin: {
//       id: 44,
//       name: 'visuels-concours_tableau_Plan de travail 1 copie 1.png-19',
//       url:
//         'https://storage.googleapis.com/download/storage/v1/b/womens/o/visuels-concours_tableau_Plan%20de%20travail%201%20copie%201.png-19?generation=1612193358457423&alt=media',
//       mimetype: 'image/png',
//       size: 208384,
//       createdAt: '2021-02-01T15:29:18.535Z'
//     },
//     thumbnail: isImage
//       ? {
//           id: 45,
//           name: 'min-visuels-concours_tableau_Plan de travail 1 copie 1.png-19',
//           url:
//             'https://storage.googleapis.com/download/storage/v1/b/womens/o/min-visuels-concours_tableau_Plan%20de%20travail%201%20copie%201.png-19?generation=1612193358660008&alt=media',
//           mimetype: 'image/png',
//           size: 208384,
//           createdAt: '2021-02-01T15:29:18.737Z'
//         }
//       : null,
//     groups: [
//       {
//         id: isImage ? 11 : 20,
//         name: isImage ? 'Picture' : 'Stills Test',
//         description: '',
//         createdAt: '2021-01-21T12:09:32.064Z',
//         updatedAt: '2021-01-21T12:09:32.064Z'
//       }
//     ]
//   }
// })

export default {
  namespaced: true,
  state: {
    pendingGridMedia: null,
    pendingUploadFileList: [],
    uploadCount: 0,
    uploadBatchCount: 0,
    list: []
  },
  getters: {
    availableTypes: state => [...new Set(state.list.map(({ type }) => type))],
    availableGroups: state =>
      state.list
        .flatMap(({ groups }) => groups)
        .reduce(
          (acc, curr) =>
            acc.every(el => el.id !== curr.id) ? [...acc, curr] : acc,
          []
        ),
    findById: state => id => state.list.find(media => media.id === id)
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    incrementUploadCount(state) {
      state.uploadCount += 1
    },
    incrementUploadBatchCount(state) {
      state.uploadBatchCount += 1
    },
    decrementUploadBatchCount(state) {
      if (state.uploadBatchCount > 0) state.uploadBatchCount -= 1
    },
    resetUploadBatchCount(state) {
      state.uploadBatchCount = 0
    },
    resetUploadCount(state) {
      state.uploadCount = 0
    },
    setPendingGridMedia(state, payload) {
      state.pendingGridMedia = payload
    },
    addPendingUploadFileList(state, files) {
      state.pendingUploadFileList = [...state.pendingUploadFileList, files]
    },
    removePendingUploadFileList(state, files) {
      state.pendingUploadFileList = state.pendingUploadFileList.filter(list => {
        return list[0] !== files[0]
      });
    },
    clearPendingUploadFileList(state) {
      state.pendingUploadFileList = []
    },
    removeMediaFromList(state, id) {
      state.list = state.list.filter(media => media.id !== id)
    }
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await axiosAdmin.get('/medias')
      commit('setList', data)
    },
    async delete({ commit }, id) {
      await axiosAdmin.delete(`/medias/${id}`)
      commit('removeMediaFromList', id)
    },
    async patch({ dispatch }, { id, update }) {
      await axiosAdmin.patch(`/medias/${id}`, update)
      await dispatch('fetch')
    }
  }
}
