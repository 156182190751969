<template>
  <div class="versionUpload e-content--maxWidth">
    <e-upload-button
      class="versionUpload__upload"
      title="Archive"
      :file="attachments.archive"
      types=".zip"
      v-model="archive"
    />
    <e-upload-button
      class="versionUpload__upload"
      title="Press Release"
      :file="attachments.pressRelease"
      types=".pdf"
      v-model="pressRelease"
    />
  </div>
</template>

<script>
export default {
  props: {
    attachments: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: new FormData(),
      archive: null,
      pressRelease: null
    }
  },
  updated() {
    if (this.archive) this.formData.set('archive', this.archive[0])
    if (this.pressRelease) this.formData.set('pressRelease', this.pressRelease[0])
  }
}
</script>

<style lang="scss">
.versionUpload {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
</style>
