<template>
  <article
    :id="`item-${data.media.id}`"
    :class="{
      'gridItem--hoverable': hoverable
    }"
    class="gridItem"
    :style="style"
  >
    <div v-if="hoverable" class="gridItem__overlay">
      <p>{{ groupName }}</p>
      <!-- <PlayIcon v-show="isVideo" /> -->
    </div>
    <div ref="inner" class="gridItem__inner">
      <slot />
    </div>
    <slot name="downloadIcon" />
  </article>
</template>

<script>
// import PlayIcon from "@/components/svg/play.svg";

export default {
  name: 'GridItem',
  // components: {
  //   PlayIcon
  // },
  props: {
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    groupName() {
      return this.data.media.groups[0] && this.data.media.groups[0].name
    },
    style() {
      
      let styles = `--width: ${this.data.width}; --height: ${this.data.height}; --col: ${this.data.col}; --row: ${this.data.row};`
      return styles
    }
  }
}
</script>

<style lang="scss">
.gridItem {
  position: relative;

  &__inner {
    overflow: hidden;
  }

  @media screen and (hover: hover) {
    $self: &;
    &--hoverable:hover {
      #{$self}__overlay,
      #{$self}__overlay p,
      #{$self}__overlay svg {
        opacity: 1;
      }
    }
  }

  &__overlay,
  &__inner {
    height: 100%;
  }

  &__inner {
    iframe {
      width: 100%;
      height: 100%;
    }

    video,
    img {
      display: block;
      object-fit: var(--fit, cover);
      object-position: center;
      width: 101%;
      height: 100%;
    }
  }

  &__insert {
    position: absolute;
    opacity: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;

    &:hover {
      opacity: 1;
    }
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--alpha-black-hover);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    padding: 0 17px;
    z-index: 2;
    &,
    p,
    svg {
      transition: opacity 300ms linear;
    }

    p,
    svg {
      opacity: 0;
      transition-delay: 150ms;
    }

    p {
      font-family: 'HelveticaNeueBold';
      font-size: 10;
      line-height: 16px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      text-align: center;
    }

    svg {
      display: block;
      margin-top: 12px;
      width: 13px;
      height: 13px;
    }
  }

  &__downloadIcon {
    position: absolute;
    bottom: 10px;
    right: 5px;
    z-index: 1;
    width: 8px;
    height: 8px;

    @media screen and (min-width: 1024px) {
      bottom: 11px;
      right: 10px;
      width: 11px;
      height: 16px;
    }

    svg {
      polygon {
        fill: grey;
      }
    }
  }
}
</style>
