<template>
  <b-page max-width class="pageGroups">
    <h1 class="e-h1">Groupes</h1>
    <b-entity-list
      v-if="groups.length > 0"
      :columns="['nom', 'archive', 'actions']"
      class="pageGroups__entryList"
    >
      <tr v-for="group in groups" :key="group.id">
        <td>{{ group.name }}</td>
        <td>{{ group.archive ? group.archive.name : 'N/A' }}</td>
        <td>
          <router-link
            class="e-link pageGroups__entryListLink"
            :to="`/admin/groups/edit/${group.id}`"
            >Modifier</router-link
          >
          <router-link
            class="e-link pageGroups__entryListLink"
            to=""
            @click.native.prevent="markGroupForDeletion(group)"
            >Supprimer</router-link
          >
        </td>
      </tr>
    </b-entity-list>

    <p v-else>Vous n'avez pas encore enregistré de groupe.</p>
    <b-popin v-if="groupMarkedForDeletion" @cancel="cancelGroupDeletion">
      <prompt-group-deletion
        :group-name="groupMarkedForDeletion.name"
        @confirm="deleteGroup"
        @cancel="cancelGroupDeletion"
      />
    </b-popin>

    <e-link class="pageGroups__button" :button="true" to="/admin/groups/edit"
      >Ajouter un
      {{ groups.length === 0 ? 'premier' : '' }}
      groupe</e-link
    >
  </b-page>
</template>

<script>
import { mapState } from 'vuex'
import PromptGroupDeletion from '../../components/elements/prompt-group-deletion'
export default {
  components: {
    PromptGroupDeletion
  },
  data() {
    return {
      groupMarkedForDeletion: null
    }
  },
  computed: {
    ...mapState({
      groups: state => state.groups.list
    })
  },
  beforeCreate() {
    this.$store.dispatch('groups/fetch')
  },
  methods: {
    markGroupForDeletion(group) {
      this.groupMarkedForDeletion = group
    },
    cancelGroupDeletion() {
      this.groupMarkedForDeletion = null
    },
    cancelGroupDeletionOnKeyUp() {
      if (this.groupMarkedForDeletion) this.cancelGroupDeletion()
    },
    deleteGroup() {
      if (!this.groupMarkedForDeletion) return
      const { id, name } = this.groupMarkedForDeletion
      this.$store.dispatch('groups/deleteItem', { id, name }).then(() => {
        this.$store.dispatch('notifications/present', {
          type: 'Notice',
          text: `Le groupe ${name} a  été supprimé`
        })
        this.groupMarkedForDeletion = null
      })
    }
  }
}
</script>

<style lang="scss">
.pageGroups {
  h1 {
    margin-bottom: 25px;
  }

  &__button {
    display: inline-block;
    // margin-top: var(--spacing-l);

    // @media (hover: hover) {
    //   &:hover {
    //     background-color: var(--black);
    //     color: var(--white);
    //     text-decoration: none;
    //     font-weight: normal;
    //   }
    // }
  }

  &__entryList {
    margin-bottom: var(--spacing-l);
    width: 100%;

    tr td:last-child {
      text-align: right;
    }

    &Link + &Link {
      margin-left: 18px;
    }
  }

  &__deletionPrompt {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--alpha-white);
  }
}

.deletionPrompt {
  background-color: var(--white);
  display: inline-block;
  padding: var(--spacing-xl);
  text-align: center;
  border: 1px solid var(--black);

  p {
    margin-top: 0;
  }
}
</style>
