<template>
  <div class="promptMediaSelection">
    <h3 class="e-h1">Sélectionner un media</h3>
    <!-- <div class="promptMediaSelection__filters">
      <e-select
        label="Filtrer par type"
        name="type"
        :value="selectedType"
        v-model="selectedType"
        class="promptMediaSelection__select"
      >
        <option value="all">Tous</option>
        <option v-for="(type, i) in availableTypes" :key="i" :value="type">{{
          type
        }}</option>
      </e-select>
      <e-select
        label="Filtrer par groupe"
        name="group"
        :value="selectedGroup"
        v-model="selectedGroup"
        class="promptMediaSelection__select"
      >
        <option value="all">Tous</option>
        <option v-for="(group, i) in availableGroups" :key="i" :value="group">{{
          group.name
        }}</option>
      </e-select>
    </div> -->
    <media-list selectable class="promptMediaSelection__mediaList" use-grid-file />
    <footer class="promptMediaSelection__footer">
      <e-button
        :disabled="!pendingGridMedia"
        @click.native.prevent="onConfirmSelection"
        >{{ confirmText }}</e-button
      >
      <e-button @click.native.prevent="$emit('cancel')">Annuler</e-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MediaList from './media-list'

export default {
  components: {
    MediaList
  },
  data() {
    return {
      // selectedType: 'all',
      // selectedGroup: 'all',
      selectedMedia: false,
      confirmText: 'Selectionner'
    }
  },
  computed: {
    ...mapState({
      // mediasList: state => state.medias.list,
      pendingGridMedia: state => state.medias.pendingGridMedia
    })
    // ...mapGetters({
    //   availableTypes: 'medias/availableTypes',
    //   availableGroups: 'medias/availableGroups'
    // })
    // filteredMedias() {
    //   const resultsByType =
    //     this.selectedType === 'all'
    //       ? this.mediasList
    //       : this.mediasList.filter(media => media.type === this.selectedType)
    //   const resultsByGroup =
    //     this.selectedGroup === 'all'
    //       ? resultsByType
    //       : resultsByType.filter(media =>
    //           media.groups.some(({ id }) => id === this.selectedGroup)
    //         )
    //   return resultsByGroup
    // }
  },
  mounted() {
    if (this.pendingGridMedia) this.confirmText = 'Remplacer'
  },
  methods: {
    onConfirmSelection() {
      this.$emit('confirm')
    },
    onCancelSelection() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
.promptMediaSelection {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 2 * var(--spacing-xl));
  max-height: 600px;
  max-width: 1024px;
  overflow: auto;
  padding: var(--spacing-xl);
  background-color: var(--white);

  h3 {
    margin-bottom: 25px;
  }

  // &__filters {
  //   display: flex;
  //   margin-bottom: var(--spacing-l);
  // }

  // &__select {
  //   width: 20%;
  //   min-width: 200px;
  // }

  // &__select + &__select {
  //   margin-left: var(--spacing-xl);
  // }

  &__mediaList {
    margin-bottom: var(--spacing-xl);
    // max-height: 400px;
  }

  &__footer {
    text-align: center;
  }
}
</style>
