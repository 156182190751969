<template>
  <div class="adminInput">
    <label v-if="label" :for="name">{{ label }}</label>
    <input
      class="adminInput__text"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      :required="required"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      @invalid="required && $emit('invalid')"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.adminInput {
  label,
  input {
    display: block;
    width: 100%;
    text-transform: uppercase;
  }

  input {
    padding: 0;
  }

  label {
    margin-bottom: var(--spacing-xs);
    font-size: 8px;
  }

  &__text {
    border: 0;
    border-bottom: 1px solid black;
    font-weight: 800;
    font-size: var(--font-s);
    // padding: var(--spacing-s) 0;
    line-height: 3em;

    &::placeholder {
      text-transform: uppercase;
      font-weight: 800;
      color: var(--alpha-black-hover);
    }
  }
}
</style>
