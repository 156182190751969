<template>
  <div class="mediaThumbnail">
    <div class="mediaThumbnail__media">
      <img
        v-if="media.type === 'image'"
        :src="media[file].url"
        loading="lazy"
      />
      <video
        v-if="media.type === 'video' || media.type === 'youtube'"
        :src="`${media[file].url}#t=0.5`"
        preload="metadata"
        loading="lazy"
      />
    </div>
    <footer class="mediaThumbnail__infos">
      <component v-if="type" :is="type" />
      <span>|</span>
      <component v-if="aspect" :is="aspect" />
      <span>|</span>
      {{ size }}
    </footer>
  </div>
</template>

<script>
import Play from '../svg/play.svg'
import Youtube from '../svg/youtube.svg'
import ImageIcon from '../svg/image.svg'
import Portrait from '../svg/portrait.svg'
import Landscape from '../svg/landscape.svg'
import formatBytes from '../../scripts/format-bytes'
export default {
  components: {
    Play,
    Youtube,
    ImageIcon,
    Portrait,
    Landscape
  },
  props: {
    media: {
      type: Object,
      required: true
    },
    useGridFile: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    file() {
      return this.useGridFile && this.media.type !== 'youtube' ? 'gridFile' : 'originFile'
    },
    aspect() {
      if (this.media.height === 2 || this.media.width === 3) return 'landscape'
      if (this.media.height === 5) return 'portrait'
      return false
    },
    type() {
      if (this.media.type === 'image') return 'image-icon'
      if (this.media.type === 'video') return 'play'
      if (this.media.type === 'youtube') return 'youtube'
      return false
    },
    size() {
      return formatBytes(this.media.originFile.size)
    }
  },
}
</script>

<style lang="scss">
.mediaThumbnail {
  position: relative;

  &__media {
    display: block;
    width: 100%;
    padding-bottom: 57.77%;
    position: relative;

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__infos {
    padding: var(--spacing-s);
    position: absolute;
    bottom: 0;
    font-size: 10px;
    line-height: 1;
    text-transform: initial;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    color: var(--white);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      margin: 0 var(--spacing-s);
    }

    svg {
      width: 15px;
      height: 15px;

      > * {
        stroke: currentColor;
      }
    }
  }
}
</style>
