<template>
  <GridItem
    :title="content.media.name"
    :hoverable="hoverable"
    :data="content"
    is-video
    @mouseenter.native="onMouseEnter"
    @mouseleave.native="onMouseLeave"
    class="gridVideo"
    :admin="admin"
    @click.native="onClick"
    :class="{ 'gridVideo--noCursor': !mousePlay }"
  >
    <transition name="fade">
      <video
        v-show="loaded"
        ref="video"
        :src="content.media[fileKey].url"
        muted
        loop
        playsinline
        :autoplay="autoplay"
      />
    </transition>
    <template
      v-if="inGrid && !hoverable && $store.state.version.canDownload"
      slot="downloadIcon"
    >
      <div class="gridItem__downloadIcon">
        <svg
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8.42 13.33"
        >
          <polygon
            points="8.42 4.63 5.66 4.63 5.66 5.13 7.92 5.13 7.92 12.83 4.46 12.83 3.96 12.83 0.5 12.83 0.5 5.13 2.76 5.13 2.76 4.63 0 4.63 0 4.88 0 5.13 0 12.83 0 13.08 0 13.33 3.96 13.33 4.46 13.33 8.42 13.33 8.42 13.08 8.42 12.83 8.42 5.13 8.42 5.13 8.42 4.63"
          />
          <polygon
            points="6.82 2.62 4.21 0 1.59 2.62 1.95 2.97 3.96 0.96 3.96 7.42 4.46 7.42 4.46 0.96 6.47 2.97 6.82 2.62"
          />
        </svg>
      </div>
    </template>
  </GridItem>
</template>

<script>
import { mapMutations } from 'vuex'
import GridItemMixin from '@/components/mixins/grid-item'

export default {
  name: 'GridImage',
  mixins: [GridItemMixin],
  props: {
    autoplay: {
      type: Boolean,
      required: false,
      default: () => false
    },
    mousePlay: {
      type: Boolean,
      required: false,
      default: () => true
    },
    inGrid: {
      type: Boolean,
      required: false,
      default: () => true
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  watch: {
    isIntersecting(value) {
      if (value) {
        this.$refs.video.load()
        this.loaded = true
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.video.load())
  },
  methods: {
    ...mapMutations({
      setPopinOpen: 'setPopinOpen'
    }),
    onMouseEnter() {
      if (this.mousePlay) this.$refs.video.play()
    },
    onMouseLeave() {
      if (this.mousePlay) this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss">
.gridVideo--noCursor {
  cursor: unset;
}
</style>
