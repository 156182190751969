<template>
  <b-page max-width class="configPage">
    <h1 class="e-h1">configuration</h1>
    <form action="" @submit.prevent="submit">
      <!-- <e-input
        class="configPage__input"
        label="Nom"
        name="name"
        placeholder="Nom du site"
        v-model="siteName"
      /> -->
      <e-textarea
        class="configPage__input"
        label="Nom"
        name="name"
        placeholder="Nom du site"
        v-model="siteName"
      />
      <e-input
        class="configPage__input"
        label="Lien Legal Notice"
        name="legal_notice_link"
        type="url"
        placeholder="Lien Legal Notice"
        v-model="legalNotice"
      />
      <e-button type="submit">Enregistrer</e-button>
    </form>
  </b-page>
</template>

<script>
import axiosAdmin from '../scripts/axios-admin'
export default {
  data() {
    return {
      legalNotice: null,
      siteName: null
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await axiosAdmin.get('/configurations')
    next(vm => {
      vm.legalNotice = data.legalNotice
      vm.siteName = data.siteName.split('<br />').join('\n')
    })
  },
  methods: {
    async submit() {
      await axiosAdmin.post('/configurations', {
        siteName: this.siteName.replace(/\r?\n/g, '<br />'),
        legalNotice: this.legalNotice
      })
      this.$store.dispatch('notifications/present', {
        type: 'Notice',
        text: 'La configuration à été mise à jour.'
      })
    }
  }
}
</script>

<style lang="scss">
.configPage {
  h1 {
    margin-bottom: 25px;
  }

  &__input {
    margin-bottom: 15px;
  }

  form {
    margin-bottom: 25px;
  }
}
</style>
