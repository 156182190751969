<template>
  <article
    :class="{ 'versionGridCell--hasMedia': item.media }"
    class="versionGridCell"
    :style="styles"
  >
    <button
      :class="{
        'versionGridCell__action--insertEnabled': item.mediaId && item.row > 1
      }"
      class="versionGridCell__action versionGridCell__action--insert"
      @click.prevent="insertMedia"
    >
      <insert />
    </button>
    <div
      :class="{ 'versionGridCell__actionOverlay--hoverable': media }"
      class="versionGridCell__actionOverlay"
    >
      <button
        v-if="!media"
        class="versionGridCell__action versionGridCell__action--add"
        @click.prevent="addMedia"
      >
        <add />
      </button>
      <div class="versionGridCell__actionMedia" v-else>
        <button
          class="versionGridCell__action versionGridCell__action--edit"
          @click.prevent="addMedia"
        >
          <edit />
        </button>
        <button
          class="versionGridCell__action versionGridCell__action--trash"
          @click.prevent="removeCell"
        >
          <trash />
        </button>
      </div>
    </div>
    <img
      v-if="media && media.type === 'image'"
      :src="media.gridFile.url"
      loading="lazy"
    />
    <video
      v-if="media && (media.type === 'video' || media.type === 'youtube')"
      loading="lazy"
      preload="metadata"
    >
      <source :src="`${media[media.type === 'youtube' ? 'originFile' : 'gridFile'].url}#t=0.5`" />
    </video>
  </article>
</template>

<script>
import Add from '../svg/add.svg'
import Insert from '../svg/insert.svg'
import Edit from '../svg/edit.svg'
import Trash from '../svg/trash.svg'
import { mapGetters } from 'vuex'

export default {
  components: {
    Add,
    Insert,
    Edit,
    Trash
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      media: null
    }
  },
  computed: {
    ...mapGetters({
      findMediaById: 'medias/findById'
    }),
    space() {
      return {
        col: this.item.col,
        row: this.item.row,
        width: this.media ? this.media.width : 1,
        height: this.media ? this.media.height : 2
      }
    },
    styles() {
      return `
        --col: ${this.space.col};
        --row: ${this.space.row};
        --width: ${this.space.width};
        --height: ${this.space.height};
      `
    },
    result() {
      return {
        ...this.space,
        uid: this.item.uid,
        mediaId: this.media ? this.media.id : null
      }
    }
  },
  mounted() {
    if (this.item.mediaId) {
      this.media = this.findMediaById(this.item.mediaId)
    }
  },
  methods: {
    addMedia() {
      return new Promise(resolve => this.$emit('addingMedia', resolve)).then(
        mediaId => {
          this.media = this.findMediaById(mediaId)
          this.$emit('mediaAdded', this.result)
        }
      )
    },
    insertMedia() {
      this.$emit('insertingMedia', this.result)
    },
    removeCell() {
      this.$emit('removingMedia', this.result)
    }
  }
}
</script>

<style lang="scss">
.versionGridCell {
  grid-column: var(--col) / span var(--width);
  grid-row: var(--row) / span var(--height);
  position: relative;

  &--hasMedia {
    background-color: blue;
  }

  &__actionOverlay,
  &__actionMedia,
  &__action--add {
    width: 100%;
    height: 100%;
  }

  &__actionOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);

    &--hoverable {
      opacity: 0;
      visibility: hidden;
    }
  }

  &:hover &__actionOverlay--hoverable {
    opacity: 1;
    visibility: visible;
  }

  &__actionMedia {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__action {
    background-color: transparent;
    border: none;
    padding: 0;

    &--enabled.versionGridCell__action--insert:hover {
      opacity: 1;
      // visibility: visible;
    }

    &--insert {
      position: absolute;
      text-align: center;
      width: 100%;
      transform: translateY(calc(-50% - 5px));
      z-index: 2;
      opacity: 0;
      pointer-events: none;

      &Enabled {
        pointer-events: auto;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
