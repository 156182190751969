<template>
  <div
    :class="{ 'contentPopin--masonry': useMassonry }"
    class="contentPopin"
    @click="() => setPopinOpen(false)"
  >
    <div class="contentPopin__inner" ref="inner" @click.stop="onClickStop">
      <header class="contentPopin__header">
        <h2>{{ title }}</h2>
        <template v-if="source !== 'embed'">
          <p v-if="source === 'grid' && canDownload">
            <a :href="popinContent.media.originFile.url" download>
              Download {{ popinContent.media.type }}
            </a>
          </p>
          <p v-if="canDownload && downloadArchive">
            <a :href="downloadArchive" download>Download full package</a>
          </p>
        </template>
        <template v-else-if="source === 'embed'">
          <p v-show="canDownload">
            <!-- TODO resolve embed youtube -->
            <button ref="embed" :data-clipboard-text="embedUrl">
              {{ copied ? 'EMBED LINK COPIED' : 'COPY EMBED LINK' }}
            </button>
          </p>
        </template>
      </header>
      <section
        ref="content"
        id="contentPopin"
        :class="{
          'contentPopin__content--grid': isGrid,
          'contentPopin__content--aspect': !isGrid,
          // TODO Check if there is only image in grid
          'contentPopin__content--gridImage': isGrid && isGridImage,
          'contentPopin__content--gridVideo': isGrid && isGridVideo,
        }"
        :style="contentPopinStyle"
        class="contentPopin__content"
      >
        <!-- TODO In "video cut down", we cannot download, but in others video group, we can download, how do we proceed ? -->
        <!-- TODO In videos grid, Press versions download the video but VIC version open a new popin, how do we proceed ? -->
        <!-- :href="
            isGrid && group.name !== 'VIDEOS CUT DOWN' && canDownload
              ? item.media_url.replace('THUMBNAILS', 'DOWNLOADS')
              : undefined
          " -->
        <a
          :href="
            canDownload &&
            isGrid &&
            group.clickItemBehaviour === 'default' &&
            item.media.originFile &&
            item.media.originFile.url
          "
          v-for="item in data"
          :key="item.id"
          download
          :class="{
            'contentPopin__item--contain': !isGrid,
            'contentPopin__item--masonry': useMassonry,
          }"
          class="contentPopin__item"
          :style="!canDownload && isGrid && 'cursor:pointer!important;'"
        >
          <component
            ref="items"
            :is="itemTag(item.media.type)"
            :content="item"
            :autoplay="item.media.type === 'video' && !isGrid"
            :in-grid="isGrid"
            :file-key="
              item.media.type === 'video' && !isGrid
                ? 'originFile'
                : 'popinFile'
            "
            mouse-play
            :can-open-popin="!canDownload"
            @loaded="onItemLoaded"
          />
        </a>
        <!-- :style="isGrid && `--aspect-ratio:calc(${item.media_ratio.join(' / ')});`" -->
      </section>
      <p
        v-if="
          !!mediaText
        "
        class="contentPopin__mediaDescription"
      >
        {{ mediaText }}
      </p>
      <p v-if="hasCredits" class="contentPopin__credit">
        {{ credits }}
      </p>
    </div>
  </div>
</template>

<script>
import Masonry from 'masonry-layout'
import { mapState, mapMutations } from 'vuex'
import GridImage from '@/components/component/grid-image'
import GridVideo from '@/components/component/grid-video'
import GridYoutube from '@/components/component/grid-youtube'
import Clipboard from 'clipboard'
import { youtubeParser } from '../../assets/scripts/youtube-parser'

export default {
  name: 'ContentPopin',
  components: {
    GridImage,
    GridVideo,
    GridYoutube,
  },
  data() {
    return {
      copied: false,
      masonry: null,
    }
  },
  watch: {
    popinContent(newValue, oldValue) {
      if (newValue !== oldValue) this.$el.scrollTo(0, 0)
    },
    $route(newRoute, oldRoute) {
      if (newRoute.hash !== oldRoute.hash) {
        this.setPopinOpen(false)
      }
    },
  },
  computed: {
    ...mapState({
      source: (state) => state.popinSource,
      popinContent: (state) => state.popinContent,
      content: (state) => state.content,
      canDownload: (state) => state.version.canDownload,
      userRole: (state) => state.userRole,
    }),
    mediaText() {
      if (this.popinContent.media) return this.popinContent.media.content
      if (this.data.length === 1) return this.data[0].media.content
      return null
    },
    contentPopinStyle() {
      let style = ''
      if (!this.isGrid && !this.isMobile) {
        style += '--aspect-ratio:var(--ratio);'
      }
      if (this.isGrid && this.group) {
        // console.log(this.group.popinColumnsCount)
        style += `--columns-count: ${this.group.popinColumnsCount}`
      }
      return style
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    },
    credits() {
      if (this.source === 'group') return this.popinContent.description
      if (this.source === 'grid') return this.popinContent.media.name
      return ''
    },
    hasCredits() {
      return !!(
        (this.source === 'group' && this.popinContent.description) ||
        (this.source === 'grid' && this.popinContent.media.name !== '')
      )
    },
    group() {
      if (this.source === 'group') return this.popinContent
      if (this.source === 'grid')
        return this.popinContent.media.groups[0] || null
      return null
    },
    downloadArchive() {
      return this.group?.archive?.url
    },
    embedUrl() {
      const id = youtubeParser(this.popinContent.media.url)
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    },
    isGrid() {
      return this.data.length > 1
    },
    isGridImage() {
      return true
      // return (
      //   this.data.every(({ media_type }) => media_type === 'image') &&
      //   this.popinContent.group_name !== 'STILLS VIDEO'
      // )
    },
    isGridVideo() {
      return (
        this.data.every(({ media_type }) => media_type === 'video') ||
        this.popinContent.group_name === 'STILLS VIDEO'
      )
    },
    title() {
      if (this.source === 'grid')
        return (
          (this.popinContent.media.groups[0] &&
            this.popinContent.media.groups[0].name) ||
          ''
        )

      if (this.source === 'group') return this.popinContent.name
      return ''
    },
    bundle() {
      return (
        this.popinContent.package ||
        this.content.groups.find(
          ({ group_id }) => this.popinContent.group_id === group_id
        ).package
      )
    },
    data() {
      if (this.source === 'embed') return [this.popinContent]
      if (this.source === 'grid') {
        return [this.popinContent]
      }
      if (this.source === 'group') {
        return this.getMediasFromGroup(this.popinContent.id).map((m) => {
          return { media: m }
        })
      }
      return []
    },
    useMassonry() {
      const check = this.data.reduce((acc, { media }) => {
        acc.add(media.width)
        acc.add(media.height)
        return acc
      }, new Set())

      return check.size > 2
    },
  },
  updated() {
    if (!this.useMassonry) {
      if (this.masonry) {
        this.masonry.destroy()
      }
    }
  },
  mounted() {
    console.log(this.popinContent, this.data)
    if (this.source === 'embed') {
      const clip = new Clipboard(this.$refs.embed)
      clip.on('success', () => (this.copied = true))
    }

    window.addEventListener('keyup', this.close, false)
    if (this.useMassonry) {
      this.masonry = new Masonry(this.$refs.content, {
        itemSelector: '.contentPopin__item',
        transitionDuration: 0,
        gutter: 7,
      })
      setTimeout(() => this.masonry.layout(), 100)
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.close, false)
  },
  destroyed() {
    if (this.masonry) this.masonry.destroy()
  },
  methods: {
    getMediasFromGroup(groupId) {
      return this.$store.state.medias.medias.filter((m) => {
        return m.groups.find((group) => group.id === groupId)
      })
    },

    ...mapMutations({
      setPopinOpen: 'setPopinOpen',
    }),
    // TODO GridYoutube peut-être ouvert dans un popin ?
    itemTag(media_type) {
      switch (media_type) {
        case 'youtube':
          return 'GridYoutube'
        case 'video':
          return 'GridVideo'
        case 'image':
          return 'GridImage'
        default:
          return null
      }
    },
    onItemLoaded() {
      if (this.masonry)
        setTimeout(() => {
          if (this.useMassonry) {
            this.masonry.layout()
          }
        }, 50)
    },
    onClickStop(e) {
      if (
        e.target instanceof HTMLVideoElement ||
        e.target instanceof HTMLImageElement ||
        e.target.tagName === 'A' ||
        e.target.tagName === 'BUTTON'
      )
        return
      this.setPopinOpen(false)
    },
    close(event) {
      if (event.keyCode === 27) {
        this.setPopinOpen(false)
      }
    },
  },
}
</script>

<style lang="scss">
.contentPopin {
  $this: &;
  color: var(--white);
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: var(--alpha-black);
  padding: 17px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50px;
  height: calc(
    100vh - 50px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 50px);

  @media screen and (min-width: 768px) {
    top: 0;
    padding: 0 0;
    height: 100vh;
  }

  &__inner {
    min-height: 100%;

    @media screen and (min-width: 768px) {
      --gap: clamp(4px, 0.3vw, 10px);
      width: 100vw;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 4.8vw) min-content;
      grid-column-gap: 10px;
      column-gap: var(--gap);
      grid-row-gap: 10px;
      row-gap: var(--gap);
    }

    @media screen and (min-width: 960px) {
      grid-template-rows: repeat(2, 5vw) min-content;
    }

    @media screen and (min-width: 1440px) {
      grid-template-rows: repeat(2, 5.2vw) min-content;
    }

    @media screen and (min-width: 1680px) {
      grid-template-rows: repeat(2, 5.35vw) min-content;
    }
  }

  &__header {
    align-self: end;
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 20px;

    @media screen and (min-width: 768px) {
      grid-row: 1 / span 2;
      grid-column: 2 / span 3;
    }

    > * {
      font: inherit;
      line-height: inherit;
      margin: 0;
    }

    button {
      color: white;
      font: inherit;
      background: transparent;
      border: unset;
      padding: 0;
      text-decoration: underline;
    }
  }

  &__content {
    grid-row-start: 3;
    align-self: start;
    // padding-bottom: 50px;
    grid-column: span 3;
    @media screen and (min-width: 768px) {
      grid-column: 2 / span 3;
    }

    &--grid {
      display: grid;
      grid-column-gap: 4px;
      column-gap: 4px;
      grid-row-gap: 4px;
      row-gap: 4px;
      --columns-count: 6;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (min-width: 768px) {
        --gap: clamp(4px, 0.3vw, 10px);
        grid-column-gap: 10px;
        column-gap: var(--gap, 10px);
        grid-row-gap: 10px;
        row-gap: var(--gap, 10px);
        grid-template-columns: repeat(var(--columns-count), 1fr);
      }
    }

    #{$this}--masonry & {
      display: block !important;
    }

    @media screen and (min-width: 768px) {
      &:not(&--grid) {
        padding-bottom: 0;
        --ratio: calc(1148 / 668);
      }
    }
  }

  &__item {
    $self: &;
    &:not(&--contain) {
      height: 100%;
    }

    > :first-child {
      height: 100%;
    }

    &--contain {
      @media screen and (min-width: 768px) {
        --fit: contain;
        height: 100%;

        img,
        video {
          height: 100%;
          width: auto;
          margin: auto;
        }
      }
    }

    &--landscape {
      grid-column: span 1;
    }

    #{$this}--masonry & {
      width: calc(33% - 7px);
      height: auto;
      margin-bottom: 7px;

      img,
      video {
        height: auto;
        width: 100%;
        margin: auto;
      }
    }
  }

  &__credit {
    font-size: 10px;
    font-family: inherit;
    text-transform: uppercase;
    grid-row-start: 4;
    grid-column: 1 / span 2;
    align-self: end;
    @media screen and (min-width: 768px) {
      padding-left: 30px;
      padding-bottom: 30px;
      padding-top: 15px;
      margin: 0;
    }
  }

  &__mediaDescription {
    grid-column: 2 / span 2;
    grid-row-start: 4;
    font-size: 10px;
    line-height: 1.2em;
    text-transform: uppercase;
    margin-top: 19px;
    white-space: pre-line;
    margin-bottom: 60px;
  }
}
</style>
