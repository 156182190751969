<template>
  <section class="indexPage">
    <main class="indexPage__main">
      <FormSignIn />
    </main>
    <PageFooter no-margin class="indexPage__footer"/>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import FormSignIn from '@/components/component/form-sign-in'
import PageFooter from '@/components/component/page-footer'

export default {
  name: 'IndexPage',
  components: {
    FormSignIn,
    PageFooter
  },
  computed: {
    ...mapState({
      isUserLoggedIn: state => state.isUserLoggedIn
    })
  },
  created() {
    this.setTheme('dark')
    this.setShowHeader(true)
    document.body.style.removeProperty('height')
  },
  methods: {
    ...mapMutations({
      setTheme: 'setTheme',
      setShowHeader: 'setShowHeader'
    })
  }
}
</script>

<style lang="scss">
.indexPage {
  height: calc(100vh - 50px);

  @media screen and (min-width: 768px) {
    height: calc(100vh - 66px);
  }

  &,
  &__main {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(100vh - 96px);

    @media screen and (min-width: 768px) {
      flex: 1 1 calc(100vh - 66px);
    }
  }

  &__main {
    background-color: var(--black);
    color: var(--white);
  }

  &__footer {
    background-color: var(--black) !important;
    color: var(--white) !important;
  }
}
</style>
