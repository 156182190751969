import Vue from 'vue'
import Vuex from 'vuex'
import fetcher from '@/assets/scripts/fetcher'
import mediasModule from './medias'
import versionsModule from './version'
import configurationModule from './configuration'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'light',
    isMenuOpen: false,
    isUserLoggedIn: false,
    isAssetsLoaded: false,
    userRole: '',
    isPopinOpen: false,
    content: {},
    popinContent: {},
    popinSource: '',
    showHeader: false,
    isLiveYoutube: false,
  },
  mutations: {
    setUserRole(state, payload) {
      state.userRole = payload

      document.body.classList.add(state.userRole)
    },
    setShowHeader(state, payload) {
      state.showHeader = payload
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    setMenuOpen(state, payload) {
      state.isMenuOpen = payload
    },
    setUserLoggedIn(state, payload) {
      state.isUserLoggedIn = payload
    },
    setAssetsLoaded(state, payload) {
      state.isAssetsLoaded = payload
    },
    setContent(state, payload) {
      state.content = payload
    },
    setPopinOpen(state, payload) {
      state.isPopinOpen = payload
    },
    setPopinContent(state, payload) {
      state.popinContent = payload
    },
    setPopinSource(state, payload) {
      state.popinSource = payload
    },
    setItem(state, data) {
      const i = state.content.findIndex(item => {
        return item.id === data.id
      })

      state.content[i] = data
    }
  },
  actions: {
    async fetchContent({ commit }) {
      const content = await fetcher.get(`/assets`)
      commit('setContent', content.data)
    },

    async authenticate({ commit, dispatch, state }, password = null) {
      let mdp = password /* || localStorage.getItem('password') */
      let version = null

      if (!mdp) return false

      if (!state.isAssetsLoaded) {
        await dispatch('medias/fetch', mdp)
        version = await dispatch('version/fetch', mdp)
        commit('setAssetsLoaded', true)
      }

      // localStorage.setItem('password', mdp)
      commit('setUserLoggedIn', true)

      return version
    }
  },
  modules: {
    medias: mediasModule,
    version: versionsModule,
    configuration: configurationModule
  }
})
