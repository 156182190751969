<template>
  <div>
    <e-button @click.native="toggleSelectAll"
      >Tout
      {{ uploadBatchCount > 0 ? 'déséléctioner' : 'séléctionner' }}</e-button
    >
    <e-button
      @click.native="showBatchEditPrompt"
      :disabled="uploadBatchCount <= 0"
      >Modifier les éléments selectionés</e-button
    >
    <b-entity-list v-for="(list, i) in pendingUploadFileList" :key="i" class="mediaEditor">
      <media-editor-line
        ref="media"
        :key="list[0].name + '--' + i"
        :files="list"
      />
    </b-entity-list>
    <b-popin v-if="showBatchEdit" @cancel="cancelBatchEdit">
      <prompt-batch-edit
        :count="uploadBatchCount"
        @confirm="batchEdit"
        @cancel="cancelBatchEdit"
      />
    </b-popin>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MediaEditorLine from './media-editor-line'
import PromptBatchEdit from '../elements/prompt-batch-edit'


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default {
  components: {
    MediaEditorLine,
    PromptBatchEdit
  },
  data() {
    return {
      showBatchEdit: false
    }
  },
  computed: {
    ...mapState({
      pendingUploadFileList: state => state.medias.pendingUploadFileList,
      uploadBatchCount: state => state.medias.uploadBatchCount
    })
  },
  methods: {
    toggleSelectAll() {
      if (
        this.uploadBatchCount > 0 &&
        this.uploadBatchCount <= this.pendingUploadFileList.length
      ) {
        this.$refs.media.forEach(media => (media.selected = false))
      } else {
        this.$refs.media.forEach(media => (media.selected = true))
      }
    },
    showBatchEditPrompt() {
      if (this.uploadBatchCount > 0) {
        this.showBatchEdit = true
      }
    },
    batchEdit({ credit, groups }) {
      this.$refs.media.forEach(media => {
        if (media.$refs.select.$refs.input.checked) {
          media.credit = credit
          media.groups = groups
          media.selected = false
        }
      })
      this.cancelBatchEdit()
    },
    cancelBatchEdit() {
      this.showBatchEdit = false
    },
    uuidv4
  }
}
</script>

<style lang="scss">
.mediaEditor {
  width: 100%;
}
</style>
